<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Operatsiya registratsiya</h3>
          </div>
        </div>
        <!-- {{ typeOfOperation }} -->
        <div class="card-body">
          <form>
            <!-- <v-combobox
              v-model="owner_name"
              :items="getAllDriverNames"
              :error-messages="ownernameErrors"
              @input="$v.owner_name.$touch()"
              @blur="$v.owner_name.$touch()"
              label="Ism Familiya"
              item-text="full_name"
              dense
              outlined
            >
            </v-combobox> -->
            <!-- {{typeOper}} -->
            {{ typeOfOperation }}
            <v-select
              v-model="typeOper"
              :items="typeOfOperation"
              item-text="type"
              item-value="typeName"
              dense
              label="Operatsiya turi"
              outlined
            ></v-select>
            <!-- <v-text-field
              v-model="number_16_digit"
              label="Karta raqami"
              maxlength="16"
              :error-messages="gidit16Errors"
              v-mask="'################'"
              @input="$v.number_16_digit.$touch()"
              @blur="$v.number_16_digit.$touch()"
              required
              outlined
            ></v-text-field> -->
            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Sana"
                  append-icon="event"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  dense
                ></v-text-field>
              </template>
              <v-date-picker v-model="date" scrollable>
                <v-spacer></v-spacer>
                <v-btn small color="error" @click="modal = false"
                  >Bekor qilish</v-btn
                >
                <v-btn small color="success" @click="$refs.dialog.save(date)"
                  >OK</v-btn
                >
              </v-date-picker>
            </v-dialog>
            <v-text-field
              v-model="amount"
              label="Summa"
              outlined
              dense
              :error-messages="remainErrors"
              @input="$v.remain.$touch()"
              @blur="$v.remain.$touch()"
            ></v-text-field>
            <v-btn class="mr-4 w-100" color="success" @click="submit"
              >Yuborish</v-btn
            >
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
// import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  validations: {
    number_16_digit: { required },
    remain: { required },
    owner_name: { required }
  },
  data() {
    return {
      typeOper: '',
      name: '',
      owner_name: '',
      owner_truck: '',
      number_16_digit: '',
      amount: '',
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false
    }
  },
  // Action
  beforeCreate() {
    this.$store.dispatch('getAllDrivers')
    this.$store.dispatch('getCorpCardsOperList')
  },
  computed: {
    getAllDrivers() {
      return this.$store.state.requests.allDrivers
    },
    getAllDriverNames() {
      const dataNames = []
      this.$store.state.requests.allDrivers.forEach((element) => {
        const name = {
          full_name: element.first + ' ' + element.last,
          id: element.id
        }
        dataNames.push(name)
      })
      return dataNames
    },
    gidit16Errors() {
      const errors = []
      if (!this.$v.number_16_digit.$dirty) return errors
      !this.$v.number_16_digit.required &&
        errors.push('Karta raqami toldirilishi shart.')
      return errors
    },
    remainErrors() {
      const errors = []
      if (!this.$v.remain.$dirty) return errors
      !this.$v.remain.required && errors.push('Summa toldirilishi shart.')
      return errors
    },
    ownernameErrors() {
      const errors = []
      if (!this.$v.owner_name.$dirty) return errors
      !this.$v.owner_name.required &&
        errors.push('Ism Fimiliya toldirilishi shart.')
      return errors
    },
    typeOfOperation() {
      return this.$store.state.requests.corpCardsOperList.results
    }
  },
  // SET_BREADCRUMB
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Moliya' },
      { title: 'Operatsiya registratsiya' }
    ])
  },
  methods: {
    submit() {
      const data = {
        operation_type: this.typeOper,
        oper_date: this.date,
        amount: this.amount,
        card: null
      }
      this.$store.dispatch('createConCardOper', data)
      this.$v.$reset()
    }
  }
}
</script>

<style></style>
